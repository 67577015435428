@-ms-viewport {
  width: device-width;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
thead {
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: transparent;
  border: 0;
  outline: 0;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
input {
  border: none;
}
input[type="submit"] {
  border: none;
  cursor: pointer;
}
button {
  align-items: unset;
}
html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1.5;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: ubunturegular;
  font-style: normal;
  src: url("/fonts/ubuntu-regular-webfont.woff2") format("woff2"), url("/fonts/ubuntu-regular-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: ubuntumedium;
  font-style: normal;
  src: url("/fonts/ubuntu-medium-webfont.woff2") format("woff2"), url("/fonts/ubuntu-medium-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-weight: normal;
  font-family: ubuntubold;
  font-style: normal;
  src: url("/fonts/ubuntu-bold-webfont.woff2") format("woff2"), url("/fonts/ubuntu-bold-webfont.woff") format("woff");
}
body,
input,
textarea,
option,
select,
button {
  color: #000000;
  font-size: 16px;
  font-family: ubunturegular, arial, Helvetica, sans-serif;
  line-height: 1.6;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ubunturegular, arial, Helvetica, sans-serif;
}
a {
  color: #e36c1b;
  text-decoration: none;
  transition: color 0.4s ease-in-out;
}
a:hover,
a:focus,
a:active {
  color: #229660;
  text-decoration: underline;
}
p,
ul,
ol,
table,
blockquote {
  margin: 0;
  padding: 0;
}
h1,
.h1 {
  font-size: 24px;
  line-height: 1.3;
  /* @media #{$large-start} {
    font-size: 24px;
    line-height: 28px;
  } */
}
h2,
.h2 {
  font-size: 20px;
  line-height: 1.3;
  /* @media #{$large-start} {
    font-size: 18px;
    line-height: 24px;
  } */
}
h3,
.h3 {
  font-size: 17px;
  line-height: 1.3;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
ul {
  list-style-type: square;
}
ul li,
ol li {
  color: #000;
}
.wrapper_main {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
/*.wrapper_1620 {
  max-width: 1620px;
}

.wrapper_1320 {
  max-width: 1320px;
}*/
.sideoffset {
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 1400px) {
  .sideoffset {
    padding-right: 0;
    padding-left: 0;
  }
}
.contentoffset {
  padding-right: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 1400px) {
  .contentoffset {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.two_column {
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (min-width: 1000px) {
  .two_column {
    width: 49%;
    margin-bottom: 0;
  }
}
/*.menu_column {
  width: 100%;

  @media only screen and (min-width: 1000px) {
    width: 366px;
  }
}

.content_column {
  width: 100%;

  @media only screen and (min-width: 1000px) {
    flex: 1;
  }
}

.two_column {
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (min-width: 1000px) {
    width: 49%;
    margin-bottom: 0;
  }
}*/
/*# sourceMappingURL=css/main.css.map */